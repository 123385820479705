import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/LockOutlined';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import AuthActions from '../../Redux/AuthRedux';
import PasswordChecker from '../../Components/Users/PasswordChecker';

type Props = {
  classes: Object,
  match: Object,
  changePassword: Function,
  isFetching: Boolean,
  t: Function,
};

const styles = theme => ({
  layout: LayoutStyles.centered400Layout,
  paper: LayoutStyles.centered400Paper,
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

class ResetPassword extends React.Component<Props> {
  state = {
    password: '',
    passwordConfirmation: '',
    passwordError: false,
  };

  handleInputChange = event => {
    const { target } = event;
    const { value, name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = event => {
    const {
      changePassword,
      match: {
        params: { token },
      },
    } = this.props;
    const { password, passwordConfirmation } = this.state;

    changePassword(password, passwordConfirmation, token);
    event.preventDefault();
  };

  render() {
    const { classes, isFetching, t } = this.props;
    const { password, passwordConfirmation, passwordError } = this.state;
    return (
      <>
        <Grid container className={classes.layout}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('WELLO.CHANGE_PASSWORD.TITLE')}
            </Typography>

            <form onSubmit={this.handleSubmit}>
              <TextField
                name="password"
                value={password}
                onChange={this.handleInputChange}
                label={t('WELLO.CHANGE_PASSWORD.PASSWORD')}
                InputProps={{
                  autoFocus: true,
                }}
                margin="normal"
                fullWidth
                required
              />
              <PasswordChecker
                password={password}
                setPasswordError={hasError => {
                  this.setState({
                    ['passwordError']: hasError,
                  });
                }}
              />

              <TextField
                name="passwordConfirmation"
                value={passwordConfirmation}
                onChange={this.handleInputChange}
                label={t('WELLO.CHANGE_PASSWORD.PASSWORD_CONFIRMATION')}
                margin="normal"
                fullWidth
                required
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isFetching || passwordError || !password || !passwordConfirmation}
              >
                {t('WELLO.CHANGE_PASSWORD.SUBMIT')}
              </Button>
            </form>
          </Paper>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.auth.isFetching,
  errorsResetPassword: state.auth.errors,
});

const mapDispatchToProps = dispatch => ({
  changePassword: (password, passwordConfirmation, token) =>
    dispatch(AuthActions.changePasswordRequest(password, passwordConfirmation, token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withTranslation()(ResetPassword)));
